import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import FormSearchHour from '../components/ConfirmationHour/FormSearchHour';
import ConfirmationInformation from '../components/ConfirmationHour/ConfirmationInformation';
import { Client } from '../components/Interfaces';
import { dateFormatter, isBefore } from '../utils/dateFunctions';

interface hourData {
  reservationCode: string,
  fecha: string,
  hora: string,
  profesional: string,
  prestacion: string,
  sucursal: string,
  status: string,
  address: string
}

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

const ConfirmationHour = ({ action }: { action: string }) => {
  const [showInformation, setShowInformation] = useState(false);
  const [hourData, setHourData] = useState({} as hourData)
  const [errorMessage, setErrorMessage] = useState('')
  const containerRef = useRef<any>()
  const [rut, setRut] = useState('')
  const [idAgendamiento, setIdAgendamiento] = useState('')



  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  async function moveToEnd() {
    await delay(1);
    const container: any = containerRef.current;
    if (container) container.scrollIntoView({ behavior: 'smooth', top: 'start' });
  }

  useEffect(() => {
    if (action === 'annulment') {
      const params = new URLSearchParams(window.location.search);
      const rutParam = params.get('rut')?.toString();
      const idAtencion = parseInt(params.get('id') as string);
      if (rutParam) {
        setRut(rutParam)
      }
      if (idAtencion) {
        setIdAgendamiento(idAtencion.toString())
      }
      searchHour(rutParam as string, idAtencion);
    }
  }, []);


  const searchHour = (rut: string, idAtencion: number) => {
    axios.post(`${baseURL}/alma/external/v1/reservationWeb`,
      {
        rut,
        id_event: idAtencion,
      },
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        if (!response.data.error) {
          if (isBefore(response.data[0].fecha, response.data[0].hora)) {
            setErrorMessage('La hora solicitada ya caducó');
            setShowInformation(false);
          } else {
            const dataDate = dateFormatter(response.data[0].fecha, response.data[0].hora);
            setHourData({
              ...response.data[0],
              reservationCode: idAtencion,
              fecha: dataDate.reservationDate,
              hora: dataDate.reservationHour,
            });
            setShowInformation(true);
            moveToEnd();
          }
        } else {
          setErrorMessage(response.data.error);
        }
      }).catch(() => {
      });
  };

  return (
    <div className="appBody">
      {(action == 'annulment') ?
        <></>
        :
        <FormSearchHour searchHour={searchHour} setRut={setRut} />
      }
      {showInformation
        ? (
          <ConfirmationInformation
            hourData={hourData}
            containerRef={containerRef}
            rut={rut}
            action={action}
          />
        ) : <h2>{errorMessage}</h2>}
    </div>
  );
};

export default ConfirmationHour;
