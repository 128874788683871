import modalConfirmApp from '../assets/images/modalConfirm.png';
import loginMaipoApp from '../assets/images/login.png';
import inicioMaipoApp from '../assets/images/inicio.png';
import perfilMaipoApp from '../assets/images/perfil.png';
import '../styles/DataPolicy.css';

const DataPolicyInformationPage = () => {
  const windowHeight = window.innerHeight;

  return (
    <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
      <div style={{
        backgroundColor: 'white', display: 'flex', flexDirection: 'column', width: '80%', paddingBottom: '15%',
      }}
      >
        <h2>
          Desactivación temporal cuenta app
        </h2>
        <p style={{ fontWeight: '600' }}>¿En que consiste la desactivación temporal de tu cuenta?</p>
        <p>Al desactivar tu cuenta Maipo, tu cuenta quedará inhabilitada temporalmente y no será posible iniciar sesión en la aplicación desde ningún dispositivo.</p>
        <p style={{ fontWeight: '600' }}>¿Como volver a activar tu cuenta?</p>
        <p>Para volver a activar tu cuenta, deberás generar una nueva contraseña en la opción "¿Olvidaste tu contraseña?" y continuar con la recuperación mediante la verificación vía SMS o mediante el correo electrónico asociado a tu cuenta.</p>
        <p style={{ fontWeight: '600' }}>¿Como desactivar temporalmente tu cuenta desde tu app?</p>
        <p>Para desactivar temporalmente tu cuenta Maipo de la app debes seguir los siguientes pasos:</p>
        <p>1. Ingresa tus datos e inicia sesión.</p>
        <img
          src={loginMaipoApp}
          className="img"
          alt="Login MaipoSalud"
        />
        <p>
          2. Una vez en el menú de inicio de la aplicación, dirigite a la opción Perfil,
          ubicada en la esquina inferior derecha.
        </p>
        <img
          src={inicioMaipoApp}
          className="img"
          alt="Menu MaipoSalud"
        />
        <p>
          3. Presiona el botón "Desactivar Cuenta".
        </p>
        <img
          src={perfilMaipoApp}
          className="img"
          alt="Profile MaipoSalud"
        />
        <p>
          4. Finalmente confirma la desactivación de tu cuenta.
        </p>
        <img
          src={modalConfirmApp}
          className="img"
          alt="Profile MaipoSalud"
        />
      </div>
    </div>
  );
};

export default DataPolicyInformationPage;
