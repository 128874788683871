import { useEffect, useRef, useState } from 'react';
import '../styles/Calendar.css';
import axios from 'axios';
import { BeatLoader, ClipLoader } from 'react-spinners';
import dayjs from 'dayjs';
import DateCalendarServerRequest from '../components/Calendar';
import { months } from '../constants/dayJsMonthTranslation';
import logo from '../assets/images/medicina_icono.jpg';
import HourCard from '../components/HourCard';
import NoProfessionalHourCard from '../components/NoProfessionalHourCard';
import {
  CalendarContainerProps, Client, DataByDate, DataByProfessional, DataFilter, DataHour,
} from './Interfaces';

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

const CalendarContainer = ({
  clientData,
  groupId,
  dataHour,
  dataLabels,
  setIsCharge,
  containerRef,
}: CalendarContainerProps) => {
  const HourTypes = {
    INIT: '0',
    EXAMS: '2',
    MEDICAL_HOURS: '1',
  };

  const headers = {
    Authorization: authorization,
    'Content-Type': 'multipart/form-data',
  };

  const [dataByDateData, setDataByDateData] = useState<{ [key: string]: DataByDate }>({});
  const [currentDay, setCurrentDay] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [existingData, setExistingData] = useState<boolean | null>(false);
  const [hourType] = useState<string>(groupId === '5' ? HourTypes.MEDICAL_HOURS : HourTypes.EXAMS);
  const [noData, setNoData] = useState<boolean>(false);
  const [, setIsOpen] = useState<boolean>(false);
  const noPaginationSpecialtiesIDs = [4, 1, 'Ecografïas Ginecológicas', 'Exámenes Cardiológicos'];

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [dataHour]);

  const getInitialData = () => {
    if (dataHour) {
      if (dataHour.doctor !== '') {
        getFutureHoursByDoctor(dataHour, clientData);
      } else {
        getFutureHours(dataHour, clientData);
      }
    }
  };

  const resetCalendar = () => {
    setNoData(false);
    setCurrentDay(dayjs().format('YYYY-MM-DD'));
    setExistingData(true);
    setDataByDateData({});
  };

  const getFutureHoursByDoctor = (hourData: DataFilter, clientDataF: Client) => {
    let dataByDate: { [key: string]: DataByDate } = {};
    resetCalendar();
    if (hourData.facility === '3') {
      dataByDate = getLaCisterna057NextHours(hourData, clientDataF, dataByDate);
    }
    axios.post(
      `${baseURL}/alma/external/v1/nextHourProfessional`,
      {
        categorie: hourData.categorie,
        age: clientDataF.edad,
        facility: hourData.facility,
        professional: hourData.doctor,
      },
      {
        headers,
      },
    ).then((response: any) => {
      dataByDate = createDictionaryOfAvailableHours(response.data, hourData);
      setDataByDateData(dataByDate);
      groupData(dataByDate);
      setIsCharge(false);
    }).catch(() => { setNoData(true); setExistingData(false); setIsCharge(false); });
  };

  const getFutureHours = (hourData: DataFilter, clientDataF: Client) => {
    let dataByDate: { [key: string]: DataByDate } = {};
    resetCalendar();
    if (hourData.facility === '3') {
      dataByDate = getLaCisterna057NextHours(hourData, clientDataF, dataByDate);
    }
    axios.post(`${baseURL}/alma/external/v1/nextHour`,
      {
        categorie: hourData.categorie,
        age: clientDataF.edad,
        facility: hourData.facility,
      },
      {
        headers,
      }).then((response: any) => {
        dataByDate = createDictionaryOfAvailableHours(response.data, hourData);
        setDataByDateData(dataByDate);
        groupData(dataByDate);
        setIsCharge(false);
      }).catch(() => { setNoData(true); setExistingData(false); setIsCharge(false); });
  };

  const getLaCisterna057NextHours = (
    hourData: DataFilter,
    clientDataF: Client,
    dict: {
      [key: string]: DataByDate
    },
  ) => {
    axios.post(`${baseURL}/alma/external/v1/nextHour`,
      {
        category: hourData.categorie,
        age: clientDataF.edad,
        facility: 10,
      },
      {
        headers,
      }).then((response: any) => {
        response.data.forEach((item: any) => {
          const date = item.fecha.slice(0, 10);
          if (!(date in dict)) {
            dict[date] = {
              fecha: item.fecha.slice(0, 10),
              consultas: [
                {
                  idGroupConsultHour: groupId,
                  idProfesional: item.id,
                  reservationHour: item.fecha,
                  profesionalName: item.nombre,
                  rutProfesional: item.rut,
                  infoProfesional: item.info,
                  facility: hourData.facility,
                  categorie: hourData.categorie,
                  preparacion: hourData.preparacion,
                  specialty: hourData.specialty,
                  reservationCode: '',
                  uid: hourData.uid,
                },
              ],
            };
          } else {
            dict[date].consultas.push({
              idGroupConsultHour: groupId,
              idProfesional: item.id,
              reservationHour: item.fecha,
              profesionalName: item.nombre,
              rutProfesional: item.rut,
              infoProfesional: item.info,
              facility: hourData.facility,
              categorie: hourData.categorie,
              preparacion: hourData.preparacion,
              specialty: hourData.specialty,
              reservationCode: '',
              uid: hourData.uid,
            });
          }
        });
      }).catch(() => {});
    return dict;
  };

  const createDictionaryOfAvailableHours = (data: any, hourData: DataFilter) => {
    const dataByDate: { [key: string]: DataByDate } = {};
    data.forEach((item: any) => {
      const date = item.fecha.slice(0, 10);
      if (!(date in dataByDate)) {
        dataByDate[date] = {
          fecha: item.fecha.slice(0, 10),
          consultas: [
            {
              idGroupConsultHour: groupId,
              idProfesional: item.id,
              reservationHour: item.fecha,
              profesionalName: item.nombre,
              rutProfesional: item.rut,
              infoProfesional: item.info,
              facility: hourData.facility,
              categorie: hourData.categorie,
              preparacion: hourData.preparacion,
              specialty: hourData.specialty,
              reservationCode: '',
              uid: hourData.uid,
            },
          ],
        };
      } else {
        dataByDate[date].consultas.push(
          {
            idGroupConsultHour: groupId,
            idProfesional: item.id,
            reservationHour: item.fecha,
            profesionalName: item.nombre,
            rutProfesional: item.rut,
            infoProfesional: item.info,
            facility: hourData.facility,
            categorie: hourData.categorie,
            preparacion: hourData.preparacion,
            specialty: hourData.specialty,
            reservationCode: '',
            uid: hourData.uid,
          },
        );
      }
    });
    return dataByDate;
  };

  const groupData = (thisData: { [key: string]: DataByDate }) => (
    (noPaginationSpecialtiesIDs.includes(parseInt(dataHour.specialty, 10))
      || hourType === HourTypes.MEDICAL_HOURS)
      ? groupDataByProfessional(thisData) : [thisData[currentDay].consultas]);

  const groupDataByProfessional = (thisData: { [key: string]: DataByDate }) => {
    const newData: { [key: string]: DataByProfessional } = {};
    thisData[currentDay].consultas?.forEach((consulta: DataHour) => {
      if (newData[consulta.profesionalName]) {
        newData[consulta.profesionalName].consultas.push(consulta);
      } else {
        newData[consulta.profesionalName] = {
          consultas: [consulta],
        };
      }
    });
    return newData;
  };

  const setDaySelected = (values: any) => { setCurrentDay(values); };

  const confirmHour = () => setIsOpen(true);

  return (
    <div className="calendarContainer">
      <div className="calendarElement" ref={containerRef}>
        {Object.keys(dataByDateData).length === 0
          ? (
            <div className="loader">
              {existingData ? (
                <ClipLoader
                  color="#78cd51"
                  loading={Object.keys(dataByDateData).length === 0}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (!existingData && noData
                ? <h2 style={{ color: '#3A3A3A' }}>No se han encontrado horarios disponibles con los datos especificados.</h2>
                : <h2>Selecciona los datos de consulta.</h2>)}

            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div className="hourInfoContainer">
                <div className="upperInfoContainer">
                  <div className="upperLeftContainer">
                    <label className="labelForm">Especialidad</label>
                    <p style={{ color: 'black', fontSize: 15 }}>
                      {dataLabels.specialty}
                    </p>
                  </div>
                  <div className="upperRightContainer">
                    <label className="labelForm">Edad</label>
                    <p style={{ color: 'black', fontSize: 15 }}>
                      {clientData.edad}
                    </p>
                  </div>
                </div>
                <div className="lowerInfoContainer">
                  <div className="upperLeftContainer">
                    <label className="labelForm">Prestación</label>
                    <p style={{ color: 'black', fontSize: 15 }}>
                      {dataLabels.category.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </p>
                  </div>
                  <div className="upperRightContainer">
                    <label className="labelForm">Sucursal</label>
                    <p style={{ color: 'black', fontSize: 15 }}>
                      {dataLabels.facility.name}
                    </p>
                  </div>
                </div>
              </div>

              <DateCalendarServerRequest
                setDayFunction={setDaySelected}
                data={dataByDateData}
              />
              <div style={{ width: '100%' }}>
                {currentDay && dataByDateData[currentDay] && (
                  (noPaginationSpecialtiesIDs.includes(parseInt(dataHour.specialty, 10))
                    || hourType === HourTypes.MEDICAL_HOURS)
                    ? (
                      Object.keys(groupData(dataByDateData)).map(
                        (professionalName: any) => (
                          <>
                            <HourCard
                              key={professionalName}
                              docName={professionalName}
                              dataHour={
                                groupDataByProfessional(
                                  dataByDateData,
                                )[professionalName].consultas
                              }
                              dataClient={clientData}
                              confirmHour={confirmHour}
                              dataLabels={dataLabels}
                            />
                          </>


                        ),
                      )
                    ) : (
                      <NoProfessionalHourCard
                        docName=""
                        dataHour={dataByDateData[currentDay].consultas}
                        dataClient={clientData}
                        confirmHour={confirmHour}
                        dataLabels={dataLabels}
                      />
                    ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CalendarContainer;
