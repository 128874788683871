import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

export const testVariables = () => {

}

export const alphabeticOrder = (a: any, b: any) => {
    if (a.label < b.label) {
        return -1
    }

    if (a.label > b.label) {
        return 1
    }

    return 0
}

export const makePostRequest = async (url: string, body?: Record<string, any>) => {
    try {
        const response = await axios.post('baseURL' + url,
            body ? body : '',
            {
                headers: {
                    Authorization: 'authorization',
                    'Content-Type': 'multipart/form-data',
                },
            });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
