import { useField } from 'formik';
import 'dayjs/locale/es';
import '../styles/React-calendar.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { MyDatePickerProps } from './Interfaces';

registerLocale('es', es);

const MyDatePicker = ({ label } : MyDatePickerProps) => {
  const [field, meta, helpers] = useField(label);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <DatePicker
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...field}
      locale="es"
      selected={value}
      value={value}
      dateFormat="dd/MM/yyyy"
      yearDropdownItemNumber={90}
      scrollableYearDropdown
      showYearDropdown
      onChange={(newValue) => {
        setValue(newValue);
      }}
    />
  );
};

export default MyDatePicker;
