import '../styles/Loader.css';

const Loader = ({ color }:any) => (
  <div className="lds-ring">
    <div style={{ borderColor: `${color} transparent transparent transparent` }} />
    <div style={{ borderColor: `${color} transparent transparent transparent` }} />
    <div style={{ borderColor: `${color} transparent transparent transparent` }} />
    <div style={{ borderColor: `${color} transparent transparent transparent` }} />
  </div>
);

export default Loader;
