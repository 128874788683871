import { useLocation } from 'react-router-dom';
import logoMaipo from '../assets/images/LogoMaiposaludBlanco.png';
import '../styles/header.css';

const Header = () => {
  const location = useLocation();
  return (
    <header>
      <div className="colorBar" />
      <div className="headerContent">
        <div className="left">
          <img className="imgHeader" src={logoMaipo} alt="Logo MaipoSalud" />
        </div>
        {/* <div className="right">
        <b>{location.pathname == "/info" ? "Información" : "Reserva de Horas"}</b>
      </div> */}
      </div>
    </header>
  );
};

export default Header;
