import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import '../../styles/confirmationService.css'

const RutValidationSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(
      /^\d{1,2}\d{3}\d{3}[-][0-9kK]{1}$/,
      { message: 'El RUT debe seguir el formato XXXXXXXX-X' },
    )
    .required('RUT es necesario.')
    .test('Is valid RUT', 'El RUT ingresado no es válido', (rut) => Fn.validaRut(rut)),
  idAtencion: Yup.number()
    .typeError('Por favor solo ingrese números')
    .required('Ingrese el id de la atención'),
});

// https://gist.github.com/donpandix/f1d638c3a1a908be02d5
const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut(rutCompleto: string) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    const tmp: Array<string> = rutCompleto.split('-');
    let digv: string|number = Number(tmp[1]) ? Number(tmp[1]) : tmp[1];
    const rut = Number(tmp[0]);
    if (digv === 'K') digv = 'k';
    return (Fn.dv(rut) === String(digv));
  },
  dv(rut: number) {
    let M = 2;
    let R = rut;
    let suma = 0;
    while (R !== 0) {
      const number = R % 10;
      suma += number * M; // 0 - 7

      R = Math.floor(R / 10);
      M += 1;

      if (M === 8) {
        M = 2;
      }
    }

    const ret = 11 - (suma % 11);
    if (ret === 11) {
      return '0';
    } if (ret === 10) {
      return 'k';
    }
    return String(ret);
  },
};

const FormSearchHour = ({ searchHour, setRut }: any) => (
  <div className="hourFormConfirmation">
    <p className="containerTitle"><b>Ingrese los datos de atención</b></p>
    <div className="formHour">
      <Formik
        initialValues={{
          rut: '',
          idAtencion: '',
        }}
        validationSchema={RutValidationSchema}
        onSubmit={(values) => {
          setRut(values.rut);
          searchHour(values.rut, parseInt(values.idAtencion));
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <p className="containerSubTitle">
                Ingrese RUT del paciente (sin puntos y con guión)
              </p>
              <Field
                id="rut"
                name="rut"
                placeholder="12345678-9"
                onChange={(e: any) => {
                  const value = e.target.value.replace(/ /g, '').replace(/\./g, '');
                  const newValue = value.replace('-', '');
                  if (newValue.length <= 7) {
                    setFieldValue('rut', newValue);
                  } else {
                    const newValue2 = newValue.replace(/ /g, '').replace('-', '');
                    setFieldValue('rut',
                      `${newValue2.substring(0, newValue2.length - 1)}-${newValue2.substring(newValue2.length - 1,
                        newValue2.length)}`);
                  }
                }}
              />
              {errors.rut && touched.rut
                ? (<div className="error">{String(errors.rut)}</div>)
                : null}
            </div>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <p className="containerSubTitle">
                Ingrese el código de reserva (Encuéntralo en tu correo)
              </p>

              <Field
                id="idAtencion"
                name="idAtencion"
                placeholder="12345678"
                onChange={(e: any) => {
                  setFieldValue('idAtencion', e.target.value);
                }}
              />
              {errors.idAtencion && touched.idAtencion
                ? (<div className="error">{String(errors.idAtencion)}</div>)
                : null}
              <button className="rutSearchButton" type="submit">Buscar</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default FormSearchHour;
