import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import '../styles/Calendar.css';
import { DateCalendarServerRequestProps } from './Interfaces';
import 'dayjs/locale/es';
import { botonConCurrentDayNoSeleccionado, botonConCurrentDaySeleccionado, botonCurrentDayDisponibleMesNoSeleccionado, botonNumeroNoDisponibleMesNoSeleccionado, botonNumerosDisponibles, botonNumerosDisponiblesMesNoSeleccionado, botonNumerosDisponiblesSeleccionados, botonNumerosNoDisponibles, containerConCurrentDayNoDisponible, containerConCurrentDayNoSeleccionado, containerConCurrentDaySeleccionado, containerConCurrrentDayNoDisponibleNoSeleccionado, containerNumeroDisponibleMesNoSeleccionado, containerNumeroSeleccionado, containerNumeroSeleccionadoPresionado, containerNumeros, containerSemanal, daysHeader, displayNone, headerMesAñoContainer, headerMesAñoLetra, letrasHeader, marginBajoContainer, marginSemanasNoVisibles } from '../calendarStyleFunctions/styles';

dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(updateLocale);
dayjs.updateLocale('es', {
  months: dayjs?.Ls.es.months?.map((month) => month.charAt(0).toUpperCase() + month.slice(1))
});
dayjs.updateLocale('en', {
  weekStart: 1,
});

function setMonthOrDayofDate(monthOrDay: number) {
  let stringMonthOrDay;
  if (monthOrDay < 10) {
    stringMonthOrDay = `0${monthOrDay.toString()}`;
  } else {
    stringMonthOrDay = monthOrDay.toString();
  }
  return stringMonthOrDay;
}

export default function DateCalendarServerRequest({
  setDayFunction,
  data,
}: DateCalendarServerRequestProps) {
  const [isLoading] = React.useState<boolean>(false);
  const [startWeekDayLabel, setStartWeekDayLabel] = React.useState<string>('');
  const [endWeekDayLabel, setEndWeekDayLabel] = React.useState<string>('');
  const [daysH, setDaysH] = React.useState<any>([]);
  const [highlighteddays, sethighlighteddays] = React.useState<number[]>([]);
  const containerRef = React.useRef<any>(null);
  const [width, setWidth] = React.useState<any>(0);
  const [currentWeek, setCurrentWeek] = React.useState<number>(dayjs().week());
  const [currentMonth, setCurrentMonth] = React.useState<number>(dayjs().month());
  const [firstOfMonth, setFirstOfMonth] = React.useState<any>(dayjs());
  const [highlightedLabels, setHighlightedLabels] = React.useState<boolean[]>([
    false, false, false, false, false, false, false,
  ]);

  function ServerDay(props: PickersDayProps<Dayjs> & {
    highlighteddays?: number[],
    sethighlighteddays?: React.Dispatch<React.SetStateAction<number[]>>
  }) {
    const { day, outsideCurrentMonth, ...other }: any = props;

    // React.useEffect(() => {
    //   if (day.week() === currentWeek && (day.$d.getDay() === 1)) {
    //     setStartWeekDayLabel(day.format('YYYY-MM-DD'));
    //   }
    //   if (day.week() === currentWeek && (day.$d.getDay() === 0)) {
    //     setEndWeekDayLabel(day.format('YYYY-MM-DD'));
    //   }
    //   // eslint-disable-next-line
    // }, []);

    const dayMonthDate = [day.month(), day.date()];
    const isSelected = isDaySelected(dayMonthDate);
    const matchedStyles = (isSelected) ? { fontWeight: 'bold', fontSize: '15px' } : { fontWeight: 'bold', fontSize: '13px', color: '#959595' };
    const deleteElement = (day.week() === currentWeek) ? {} : { display: 'none' };

    function isDaySelected(dayMonthDate: number[]): boolean {
      return highlighteddays.some((arr) => JSON.stringify(arr) === JSON.stringify(dayMonthDate));
    }
    const margin = { marginTop: '36px' };
    const color = (dayjs(new Date(dayjs().year(), currentMonth, 1)).month() == day.month()) ? {} : { color: '#cbcacb' };

    return (
      <Badge
        key={day.toString()}
        badgeContent={undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiBadge-badge': {
            right: '50%',
          },
        }}

      >
        <PickersDay
          // eslint-disable-next-line
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          sx={{
            ...matchedStyles, ...margin, ...color,
          }}
        />
      </Badge>
    );
  }

  React.useEffect(() => {
    const container: any = containerRef.current;
    container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    moment.updateLocale('en', { week: { dow: 1 } });
  }, []);

  React.useEffect(() => {
    const updateHeight = () => {
      const containerElement = containerRef.current;
      if (containerElement) {
        const widthContainer = containerElement.offsetWidth;
        setWidth(widthContainer * 0.0625);
      }
    };
    window.addEventListener('resize', updateHeight);
    updateHeight(); // Actualizar la altura inicialmente

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  React.useEffect(() => {
    const daysHH: any = [];
    if (data) {
      Object.keys(data).forEach((date) => {
        daysHH.push([parseInt(dayjs(date).format('M'), 10), parseInt(dayjs(date).format('D'), 10)]);
      });
      setDaysH(daysHH);
      setHighlited(daysHH, parseInt(dayjs().format('M'), 10));
      // setLabels(currentWeek);
    }
    // eslint-disable-next-line
  }, [])

  // React.useEffect(() => {
  //   setLabels(currentWeek);
  // }, [highlighteddays])

  function setSelectedDate(date: any) {
    setDayFunction(`${date.year()}-${setMonthOrDayofDate(date.month() + 1)}-${setMonthOrDayofDate(date.date())}`);
  }

  function setHighlited(days: [], actualMonth: number) {
    const toHighlight: any = [];
    days.forEach((day: any) => {
      toHighlight.push([day[0] - 1, day[1]]);
    });
    sethighlighteddays(toHighlight);
  }

  const selectDay = (selectedDay: string) => {
    const day = selectedDay.toUpperCase();

    if (day === 'DO') {
      return 'D';
    }
    if (day === 'LU') {
      return 'L';
    }
    if (day === 'MA') {
      return 'M';
    }
    if (day === 'MI') {
      return 'M';
    }
    if (day === 'JU') {
      return 'J';
    }
    if (day === 'VI') {
      return 'V';
    }

    return 'S';
  };

  const handleMonthChange = (date: Dayjs) => {

    if (currentMonth > date.month() && date.year() > firstOfMonth.year()) {
      // setCurrentWeek(date.week());
      setCurrentMonth(date.month());
      setSelectedDate(dayjs().year(date.year()).startOf('year'));
      setFirstOfMonth(dayjs().year(date.year()).startOf('year'));
    } else {
      setCurrentMonth(date.month());
      setSelectedDate(dayjs().year(date.year()).week(date.week() + 1).startOf('week'));
      setFirstOfMonth(dayjs().year(date.year()).week(date.week() + 1).startOf('week'));
    }
  };

  function goNextWeek() {
    setLabels(currentWeek + 1);
    if ((currentMonth + 1).toString().padStart(2, '0') === moment().year(firstOfMonth.year()).week(currentWeek + 1).startOf('week')
      .format('MM')) {
      setCurrentWeek(currentWeek + 1);
    } else {
      handleMonthChange(dayjs(new Date(firstOfMonth.year(), firstOfMonth.month() + 1, 1)));
    }
  }

  function setLabels(week: number) {
    const nuevoArray = [false, false, false, false, false, false, false];
    highlighteddays.forEach((day: any, i: any) => {
      const newDay: any = dayjs(new Date(firstOfMonth.year(), day[0], day[1]));
      if ((newDay.week()) === week) {
        nuevoArray[newDay.$W - 1] = true;
      }
    });
    setHighlightedLabels(nuevoArray);
  }

  function goPreviousWeek() {
    setLabels(currentWeek - 1);
    if ((currentMonth + 1).toString().padStart(2, '0') === moment().year(firstOfMonth.year()).week(currentWeek - 1).endOf('week')
      .format('MM')) {
      setCurrentWeek(currentWeek - 1);
    } else {
      handleMonthChange(dayjs(
        new Date(firstOfMonth.year(), firstOfMonth.month() - 1,
          dayjs(new Date(firstOfMonth.year(), firstOfMonth.month() - 1, 1)).daysInMonth()),
      ));
    }
  }

  return (

    (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <div className="weekStartEndLabel">
          {/*
        >
          Semana del
          {' '}
          {dayjs(startWeekDayLabel).format('DD')}
          {' '}
          {months[dayjs(startWeekDayLabel).format('MMM')]}
          {' '}
          al
          {' '}
          {dayjs(endWeekDayLabel).format('DD')}
          {' '}
          {months[dayjs(endWeekDayLabel).format('MMM')]}
        </p> */}
        </div>
        <DateCalendar
          ref={containerRef}
          sx={{
            width: '100%',
            // height: '25vh',
            borderColor: 'black',
            borderWidth: '5px',
            marginBottom: '3%',
            // maxHeight: '250px',
            '& .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition': {
              overflow: 'clip',
              // height: '200px',
            },
            '& .css-1a3v1c-MuiBadge-root': containerNumeros,
            '& .css-1fvxlgl': containerNumeros,
            '& .css-1a3v1c-MuiBadge-root:has(.css-1iy6hwc-MuiButtonBase-root-MuiPickersDay-root)': containerNumeroSeleccionado,
            '& .css-1fvxlgl:has(.css-1a5n94v)': containerNumeroSeleccionado,
            '& .css-1a3v1c-MuiBadge-root:has(.css-1iy6hwc-MuiButtonBase-root-MuiPickersDay-root.Mui-selected)': containerNumeroSeleccionadoPresionado,
            '& .css-1fvxlgl:has(.css-1a5n94v.Mui-selected)': containerNumeroSeleccionadoPresionado,
            '& .css-1a3v1c-MuiBadge-root:has(.css-131d115-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected))': containerConCurrentDayNoSeleccionado,
            '& .css-1fvxlgl:has(.css-y1cafz:not(.Mui-selected))': containerConCurrentDayNoSeleccionado,
            '& .css-5jtps6-MuiButtonBase-root-MuiPickersDay-root': botonNumeroNoDisponibleMesNoSeleccionado,
            '& .css-mm1tvw': botonNumeroNoDisponibleMesNoSeleccionado,
            '& .css-1a3v1c-MuiBadge-root:has(.css-131d115-MuiButtonBase-root-MuiPickersDay-root.Mui-selected)': containerConCurrentDaySeleccionado,
            '& .css-1fvxlgl:has(.css-y1cafz.Mui-selected)': containerConCurrentDaySeleccionado,
            '& .css-1a3v1c-MuiBadge-root:has(.css-mficx-MuiButtonBase-root-MuiPickersDay-root)': containerNumeroDisponibleMesNoSeleccionado,
            '& .css-1a3v1c-MuiBadge-root:has(.css-17032jz-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected))': containerNumeroDisponibleMesNoSeleccionado,
            '& .css-1fvxlgl:has(.css-1bfn96o)': containerNumeroDisponibleMesNoSeleccionado,
            '& .css-1fvxlgl:has(.css-edtxtx:not(.Mui-selected))': containerNumeroDisponibleMesNoSeleccionado,
            '& .css-131d115-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': botonConCurrentDaySeleccionado,
            '& .css-y1cafz.Mui-selected': botonConCurrentDaySeleccionado,
            '& .css-131d115-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': botonConCurrentDayNoSeleccionado,
            '& .css-y1cafz:not(.Mui-selected)': botonConCurrentDayNoSeleccionado,
            '& .css-1y74a62-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': containerConCurrentDayNoDisponible,
            '& .css-17r4ujk.Mui-selected': containerConCurrentDayNoDisponible,
            '& .css-1y74a62-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': containerConCurrrentDayNoDisponibleNoSeleccionado,
            '& .css-17r4ujk:not(.Mui-selected)': containerConCurrrentDayNoDisponibleNoSeleccionado,

            '& .css-1ncupo1-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
              backgroundColor: 'white',
              color: '#959595',
            },
            '& .css-rf6an4.Mui-selected': {
              backgroundColor: 'white',
              color: '#959595',
            },
            '& .css-1iy6hwc-MuiButtonBase-root-MuiPickersDay-root': botonNumerosDisponibles,
            '& .css-1iy6hwc-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': botonNumerosDisponiblesSeleccionados,
            '& .css-1a5n94v': botonNumerosDisponibles,
            '& .css-mficx-MuiButtonBase-root-MuiPickersDay-root': botonNumerosDisponiblesMesNoSeleccionado,
            '& .css-1bfn96o': botonNumerosDisponiblesMesNoSeleccionado,
            // '& .css-q4jfs2-MuiDateCalendar-root .css-mficx-MuiButtonBase-root-MuiPickersDay-root': {
            //   height: '13.6%',
            // },
            // '& .css-196h6bi-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
            //   margin: '0 0px',
            // },
            '& .css-2jurxj-MuiDayCalendar-slideTransition': marginBajoContainer,
            '& .css-1h8fkga': marginBajoContainer,

            '& .css-9j3yq8-MuiDateCalendar-root:has(.css-1ncupo1-MuiButtonBase-root-MuiPickersDay-root.css-1ncupo1-MuiButtonBase-root-MuiPickersDay-root.Mui-selected)': {
              backgroundColor: 'white',
            },
            // '& .css-1y74a62-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
            //   margin: '0 0px',
            // },
            // '& .css-1qs309j-MuiButtonBase-root-MuiPickersDay-root': {
            //   width: `${width * 0.9}px`,
            //   height: `${width * 0.9}px`,
            // },
            // '& .css-mficx-MuiButtonBase-root-MuiPickersDay-root': {
            //   border: 'solid 1px',
            //   borderTop: '0px',
            //   borderColor: '#5ab542',
            //   borderRadius: '10px',
            // },

            //Letras Header L M M J V S D
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel': letrasHeader,
            '& .css-10qkmc3': letrasHeader,
            // Headers
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="lunes"]': daysHeader(1, 0, firstOfMonth, currentWeek, highlightedLabels),
            '.css-10qkmc3[aria-label="lunes"]': daysHeader(1, 0, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="martes"]': daysHeader(2, 1, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="martes"]': daysHeader(2, 1, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="miércoles"]': daysHeader(3, 2, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="miércoles"]': daysHeader(3, 2, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="jueves"]': daysHeader(4, 3, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="jueves"]': daysHeader(4, 3, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="viernes"]': daysHeader(5, 4, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="viernes"]': daysHeader(5, 4, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="sábado"]': daysHeader(6, 5, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="sábado"]': daysHeader(6, 5, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel[aria-label="domingo"]': daysHeader(0, 6, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-10qkmc3[aria-label="domingo"]': daysHeader(0, 6, firstOfMonth, currentWeek, highlightedLabels),
            '& .css-1tx2mzj-MuiDateCalendar-root': {
              marginTop: '10%',
            },
            '& .css-12p2adl-MuiDayCalendar-monthContainer': containerSemanal,
            '& .css-i6bazn': containerSemanal,
            // '& .css-flbe84-MuiDayCalendar-weekContainer': {
            // },
            // Header
            '& .css-i5q14k-MuiDayCalendar-header': {
              backgroundColor: 'white',
            },
            '& .css-1n2mv2k': {
              backgroundColor: 'white',
            },

            // '& .css-7oawqu-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
            //   margin: '0 0px',
            //   width: `${width * 0.9}px`,
            //   height: `${width * 0.9}px`,
            // },
            // '& .css-7oawqu-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
            //   width: `${width * 0.9}px`,
            //   height: `${width * 0.9}px`,
            //   border: 'solid 1px',
            // },
            // ' & .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton': {
            //   width: '40px',
            //   height: '40px',
            // },
            '& .css-1ncupo1-MuiButtonBase-root-MuiPickersDay-root': botonNumerosNoDisponibles,
            '& .css-rf6an4': botonNumerosNoDisponibles,
            // '& .css-1vooibu-MuiSvgIcon-root': {paddingTop: 0},
            // '& .css-k008qs': displayNone,
            '& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {position: 'absolute', right: 0, width: '15%', top: 0, padding: 0},
            '& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button:hover': {background: 'transparent'},
            '& .css-11wxb': {position: 'absolute', left: 0, width: '15%', top: 0, padding: 0},
            '& .css-11wxb:hover': {background: 'transparent'},
            // '& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': displayNone,
            '& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {position: 'absolute', left: 0, width: '15%', top: 0, padding: 0},
            '& .css-1vooibu-MuiSvgIcon-root': {color: '#5bb543', height: '1.3em', width: '1.3em'},
            '& .css-1cw4hi4': {color: '#5bb543', height: '1.3em', width: '1.3em'},
            '& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button:hover': {background: 'transparent'},
            '& .css-1fklenr': {position: 'absolute', right: 0, width: '15%', top: 0, padding: 0},
            '& .css-1fklenr:hover': {background: 'transparent'},
            '& .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton': displayNone,
            // '& .css-1cw4hi4': displayNone,
            '& .css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton': displayNone,
            '& .css-1wjkg3': displayNone,

            ' .css-17032jz-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': botonCurrentDayDisponibleMesNoSeleccionado,
            ' .css-edtxtx:not(.Mui-selected)': botonCurrentDayDisponibleMesNoSeleccionado,


            // Heade febrero 2024
            '& .css-nk89i7-MuiPickersCalendarHeader-root': headerMesAñoContainer,
            '& .css-1dozdou': headerMesAñoContainer,

            '.css-cyfsxc-MuiPickersCalendarHeader-labelContainer': headerMesAñoLetra,
            ' & .css-16j77m4': headerMesAñoLetra,

            '& .css-1lkpf4l-MuiYearCalendar-root': {
              display: 'none',
              pointerEvents: 'none',
            },
            '& .css-mvmu1r': marginSemanasNoVisibles,
            '& .css-flbe84-MuiDayCalendar-weekContainer': marginSemanasNoVisibles,
          }}
          showDaysOutsideCurrentMonth
          dayOfWeekFormatter={(day: string) => selectDay(day) as string}
          defaultValue={dayjs()}
          value={firstOfMonth}
          loading={isLoading}
          reduceAnimations
          onChange={(value: any) => {
            setFirstOfMonth(value);
            setSelectedDate(value);
            if (value.month() != currentMonth) {
              setCurrentMonth(value.month());
              setHighlited(daysH, dayjs(value).month() + 1);
            }
          }}
          onMonthChange={(e) => {handleMonthChange(e)}}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: (highlighteddays.length !== 0 && width !== 0 && daysH.length !== 0)
              ? ServerDay : undefined,
          }}
          slotProps={{
            day: {
              highlighteddays,
            } as any,
          }}

        />
        {/* <div className="leftArrow">
          <MdKeyboardArrowLeft color="#5ab542" size={25} onClick={() => goPreviousWeek()} />
        </div>
        <div className="rightArrow">
          <MdKeyboardArrowRight color="#5ab542" size={25} onClick={() => goNextWeek()} />
        </div> */}
      </LocalizationProvider>
    )
  );
}
