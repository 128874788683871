import { borderRadius, color, fontSize, fontWeight, height, margin, width } from "@mui/system";
import { he } from 'date-fns/locale';

export const daysHeader = (
  dayOfWeek: number,
  labelIndex: number,
  firstOfMonth: any,
  currentWeek: number,
  highlightedLabels: boolean[]
) =>
  highlightedLabels[labelIndex]
    ? {}
    : {};

export const headerMesAñoContainer = {
  backgroundColor: "white",
  margin: "0 0px",
  borderTopRightRadius: "8px",
  borderTopLeftRadius: "8px",
  justifyContent: "center",
  marginTop: "6%",
  color: "#3A3A3A",
  paddingBottom: "2%",
  paddingTop: "1%",
  position: "relative",
};

export const headerMesAñoLetra = {
  width: "70%",
  height: "30px",
  margin: "0 0px",
  color: "#7c7c7c",
  // fontSize: "16px",
  fontWeight: "bold",
  marginLeft: "6.3%",
  marginRight: "1.8%",
  justifyContent: "center",
  "pointer-events": "none",

};

export const letrasHeader = {
  width: "9%",
  height: "30px",
  margin: "0 0px",
  color: "#3A3A3A",
  fontSize: "14px",
  fontWeight: "bold",
  marginLeft: "1.8%",
  marginRight: "1.8%",
  border: "solid 1px transparent",
};

export const containerNumeros = {
  // height: "9.23%",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  margin: "0 0px",
  width: "9%",
  marginLeft: "1.8%",
  marginRight: "1.8%",
  border: 'solid 1px transparent',
  height: "24px",
};

export const botonNumerosDisponibles = {
  margin: "0 0px",
  // borderRadius: "0px",
  // borderTop: "0px",
  width: "100%",
  borderRadius: "7px",
  fontSize: '13px',
  height: "24px"
};

export const botonNumerosDisponiblesSeleccionados = {
  fontWeight: "bolder",

};


export const botonNumerosNoDisponibles = {
  width: "100%",
  margin: "0 0px",
  borderRadius: "0px",
  cursor: "not-allowed",
  pointerEvents: "none",
  fontWeight: "normal"
};

export const botonNumerosDisponiblesMesNoSeleccionado = {
  margin: "0 0px !important",
  // borderRadius: "0px",
  // borderTop: "0px !important",
  width: "100% !important",
  // borderBottomRightRadius: "7px",
  // borderBottomLeftRadius: "7px",
  borderRadius: "7px",
  fontSize: "13px",
  height: "24px",
  color: "#3A3A3A"
};

export const containerNumeroSeleccionado = {
  height: "14.23%",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  margin: "0 0px",
  // border: "solid 1px",
  // borderTop: "0px",
  borderColor: "#5ab542",
  borderRadius: "8px",
  // borderBottomRightRadius: "8px",
  // borderBottomLeftRadius: "8px",
  width: "9%",
  marginLeft: "1.8%",
  marginRight: "1.8%",
  fontWeight: "bolder !important",
};

export const containerNumeroSeleccionadoPresionado = {
  height: "14.23%",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  margin: "0 0px",
  // border: "solid 1px",
  // borderTop: "0px",
  borderColor: "#1976d2",
  // borderBottomRightRadius: "8px",
  // borderBottomLeftRadius: "8px",
  borderRadius: "8px",
  width: "9%",
  marginLeft: "1.8%",
  marginRight: "1.8%",
  fontWeight: "bolder",
};

export const containerNumeroDisponibleMesNoSeleccionado = {
  height: "14.23%",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  margin: "0 0px",
  border: "solid 1px",
  // borderTop: "0px",
  borderColor: "#5ab542",
  // borderBottomRightRadius: "8px",
  // borderBottomLeftRadius: "8px",
  borderRadius: "8px",
  width: "9%",
  marginLeft: "1.8%",
  marginRight: "1.8%",

};

export const displayNone = {
  display: "none",
};

export const containerSemanal = {
  backgroundColor: "white",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  height: "222px",
};

export const marginSemanasNoVisibles = {
  margin: "10px 0px",
};

export const containerConCurrentDaySeleccionado = {
  border: "solid 1px",
  // borderTop: "0px !important",
  borderColor: "#1976d2",
  // borderBottomRightRadius: "8px",
  // borderBottomLeftRadius: "8px",
  borderRadius: "8px",
  fontSize: "13px",

};

export const botonConCurrentDaySeleccionado = {
  margin: "0 0px",
  // borderRadius: "0px",
  // borderTop: "0px",
  width: "100%",
  // borderBottomRightRadius: "7px",
  // borderBottomLeftRadius: "7px",
  borderRadius: "7px",
  fontSize: "13px",
  height: "24px",
  fontWeight: "bolder",
};

export const botonConCurrentDayNoSeleccionado = {
  margin: "0 0px",
  // borderRadius: "0px",
  // borderTop: "0px",
  width: "100%",
  border: "0px",
  // borderBottomRightRadius: "7px",
  // borderBottomLeftRadius: "7px",
  borderRadius: "7px",
  fontSize: "13px",
  height: "24px",

};

export const containerConCurrentDayNoSeleccionado = {
  border: "solid 1px",
  // borderTop: "0px !important",
  borderColor: "#5ab542",
  // borderBottomRightRadius: "7px",
  // borderBottomLeftRadius: "7px",
  borderRadius: "7px",
  height: "24px",

};

export const botonNumeroNoDisponibleMesNoSeleccionado = {
  margin: "0 0px",
  borderRadius: "0px",
  cursor: "not-allowed",
  pointerEvents: "none",
  height: "24px",
  fontWeight: "normal"
};

export const marginBajoContainer = {
  minHeight: "0px",
  marginBottom: "3%",
};

export const containerConCurrentDayNoDisponible = {
  margin: '0 0px',
  border: '0 0px',
  bordeColor: '#5ab542',
  "pointer-events": "none",
  backgroundColor: 'white',
  color: '#959595',
  fontWeight: 'bold',
  height: "24px",
}

export const botonCurrentDayDisponibleMesNoSeleccionado = {
  margin: "0 0px",
  width: "100%",
  borderRadius: "7px",
  fontSize: "13px",
  height: "24px",
  border: 'solid 1px transparent',
  color: "#3A3A3A"

};

export const containerConCurrrentDayNoDisponibleNoSeleccionado = {
  margin: '0 0px',
  border: '0 0px',
  borderColor: 'transparent',
  "pointer-events": "none",
  backgroundColor: 'white',
  color: '#959595',
  fontWeight: 'bold',
  height: "24px",
}

// export const leftArrowButtonHover = {
//   borderRadius
