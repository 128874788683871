import dayjs from 'dayjs';

export const dateFormatter = (date: string, hour?: string) => {
    const fecha = hour ? dayjs(`${date} ${hour}`).locale('es') :  dayjs(date).locale('es');
  
    const hora = fecha.format('HH');
    const minuto = fecha.format('mm');
    const dia = fecha.format('dddd');
    const month = fecha.format('MMMM');
    const dayNumber = fecha.date();
    const capitalizedDay = dia.charAt(0).toUpperCase() + dia.slice(1);
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  
    const reservationDate = `${capitalizedDay} ${dayNumber} de ${capitalizedMonth}`;
    const reservationHour = `${hora}:${minuto}`;
  
    return { reservationDate, reservationHour };
  };

export const isBefore = (day: string, hour: string ) => {
  const date = new Date(`${day} ${hour}`)
  const todayDate = new Date();
  return date < todayDate;
}