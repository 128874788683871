import '../styles/Footer.css';
import logoMaipo from '../assets/images/LogoMaiposalud.png';

const Footer = () => (
  <footer>
    <div className="colorBar" />

    {/* <div className="right" /> */}
  </footer>
);

export default Footer;
