import { useState, useEffect, Fragment } from 'react';
import { Grid, Paper, styled } from '@mui/material';
import {
  AiFillCaretLeft, AiFillCaretRight,
} from 'react-icons/ai';
import '../styles/HourCard.css';
import 'dayjs/locale/es';
import _ from 'lodash';
import { DataHour, HourCardProps } from './Interfaces';
import PopUpConfirmation from './PopUpConfirmation';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: '#079ae7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',

}));

const NoProfessionalHourCard = ({ dataHour, dataClient, dataLabels }: HourCardProps) => {
  const [pagina, setPagina] = useState<number>(1);
  const [porPagina] = useState<number>(16);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hourSelected, setHourSelected] = useState<DataHour>({} as DataHour);
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const closeModal = () => setIsOpen(false);
  const [newData, setNewData] = useState<any>({});
  const maximo = dataHour.length / porPagina;
  // const maximo = selectedModule != null ? newData[selectedModule].quantity / porPagina : 0;
  // const openModalConfirmation = (openModalConfirmationData: any) => {
  //   const moduleSelected = newData[selectedModule].hours;
  //   const randomDoctorIndex = getRandomInt(moduleSelected[openModalConfirmationData].length);
  //   const randomHour = moduleSelected[openModalConfirmationData][randomDoctorIndex];
  //   randomHour.reservationHour = openModalConfirmationData;
  //   setHourSelected(randomHour);
  //   setIsOpen(true);
  // };
  const openModalConfirmation = (openModalConfirmationData: DataHour) => {
    setHourSelected(openModalConfirmationData);
    setIsOpen(true);
  };

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    const dict: any = {};
    for (let horaModulo = 8; horaModulo < 20; horaModulo += 2) {
      const stringHoraModulo = `${horaModulo.toString().padStart(2, '0')}:00 - ${(horaModulo + 2).toString().padStart(2, '0')}:00`;
      dict[stringHoraModulo] = [];
      Object.values(dataHour).forEach((element: DataHour) => {
        const horaConsulta = parseInt(element.reservationHour.substring(11, 13), 10);
        if (horaModulo <= horaConsulta && horaConsulta < horaModulo + 2) {
          dict[stringHoraModulo].push(element);
        }
      });
    }
    Object.keys(dict).map((key: any) => {
      const grouped = _.mapValues(_.groupBy(dict[key], 'reservationHour'), (clist) => clist.map((car) => _.omit(car, 'reservationHour')));
      dict[key] = {};
      dict[key].hours = grouped;
      dict[key].quantity = Object.keys(dict[key].hours).length;
    });
    setNewData(dict);
  }, [dataHour]);

  useEffect(() => {
    setSelectedModule(null);
    setPagina(1);
  }, [newData]);

  return (
    <div className="noProfessionalHourContainer">
      <div className="hourCardBody">
        <div className="noProfessionalHourBodyColumn">
          <div className="docContainer">
            {/* <p className="docInfo">{docName}</p> */}
            <p className="docInfo" style={{marginLeft: '2.5%'}}>{dataLabels.specialty}</p>
            <p className="docSpecialty" style={{marginLeft: '2.5%'}}>{dataLabels.category.slice(0, 35).toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}{dataLabels.category.length > 35 ? "..." : ""}</p>
          </div>
          {/* {selectedModule == null
            ? (
              <Grid container spacing={1} marginY={2} columns={18}>
                {Object.keys(newData).map((d: any) => (
                  <Fragment key={d}>
                    {newData[d].quantity !== 0
                    && (
                    <Grid item xs={18} sm={9} lg={6}>
                      <Item
                        onClick={() => {
                          (newData[d].quantity !== 0
                            ? (setSelectedModule(d), setPagina(1)) : null);
                        }}
                        style={{ fontSize: 'calc(7px + .55vw)' }}
                      >
                        <p>
                          {d}
                        </p>
                        {newData[d].quantity}
                        {' '}
                        {' Horas disponibles'}
                      </Item>
                    </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            )
            : ( */}
          <Grid container spacing={1} marginY={2} columns={12} paddingRight={'3%'} paddingLeft={'3%'}>
            {dataHour.slice((pagina - 1) * porPagina + (pagina != 1 ? -(2 * (pagina - 1) - 1) : 0), (pagina - 1) * porPagina + (pagina < Math.ceil(maximo) && pagina > 1 ? porPagina - (2 * pagina - 1) : porPagina - 1)).map(
              (consulta: any, i: number) => (
                <Fragment key={i}>
                  <Grid item xs={3} sm={3} lg={3}>
                    <Item
                      className="item"
                      style={{ fontSize: 'calc(7px + .4vw)' }}
                      onClick={() => {
                        openModalConfirmation(consulta);
                      }}
                    >
                      {' '}
                      {consulta.reservationHour.substring(10, 16)}
                      {' '}
                    </Item>
                  </Grid>
                </Fragment>
              ),
            )}
            <div className={pagina > 1 ? "bottomCard" : "bottomCardDisplayNone"}>
              {pagina > 1 ? <button type="button" className="nextPageButton" onClick={() => setPagina(pagina - 1)}><MdKeyboardDoubleArrowLeft className="icon" /></button> : null}
              {/* {pagina < Math.ceil(maximo) ? <button className="nextPageButton" type="button" onClick={() => setPagina(pagina + 1)}><MdKeyboardDoubleArrowRight className="icon" /></button> : null} */}
            </div>
            <div className={pagina < Math.ceil(maximo) ? "bottomCard" : "bottomCardDisplayNone"}>
              {/* {pagina > 1 ? <button type="button" style={{borderTopRightRadius: 5}} className="nextPageButton" onClick={() => setPagina(pagina - 1)}><MdKeyboardDoubleArrowLeft className="icon" /></button> : null} */}
              {pagina < Math.ceil(maximo) ? <button className="nextPageButton" type="button" onClick={() => setPagina(pagina + 1)}><MdKeyboardDoubleArrowRight className="icon" /></button> : null}
            </div>
          </Grid>
          <PopUpConfirmation
            isOpen={isOpen}
            closeModal={closeModal}
            hourSelected={hourSelected}
            dataLabels={dataLabels}
            dataClient={dataClient}
          />
        </div>
      </div>
    </div>
  );
};

export default NoProfessionalHourCard;
