
import axios from 'axios';
import { useState, useEffect } from 'react';

const authorization = process.env.REACT_APP_AUTHORIZATION;

export function useFetchGet(url: string) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url,
          {
            headers: {
              Authorization: authorization,
              'Content-Type': 'application/json',
            },
          });
        setData(response.data);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
}

