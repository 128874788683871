import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import FilterScreen from './pages/FilterScreen';
import HourConfirmationPage from './pages/HourConfirmationPage';
import HourReservation from './pages/HourReservation';
import RutPage from './pages/RutPage';
import Header from './components/Header';
import Footer from './components/Footer';
import ConfirmationPage from './pages/ConfirmationPage';
import CancelReservation from './pages/CancelReservation';
import ConfirmationHour from './pages/ConfirmationHour';
import DataPolicyInformationPage from './pages/dataPolicyInformationPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="/confirmation" element={<ConfirmationHour action='confirmation' />} />
        <Route path="/annulment" element={<ConfirmationHour action='annulment' />} />
        <Route path="/" element={<RutPage />} />
        <Route path="/calendar/confirmation" element={<HourConfirmationPage />} />
        <Route path="/filter" element={<FilterScreen />} />
        <Route path="/hour-reservation" element={<HourReservation />} />
        <Route path="/reservation-data" element={<ConfirmationPage />} />
        <Route path="/cancel-reservation" element={<CancelReservation />} />
        <Route path="/info" element={<DataPolicyInformationPage />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
